
#CollapsebaleSection {
  .cs-pageTitle {
      font-family: 'Gotham Rounded Medium';
      font-size: 14px;
      line-height: 17px;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      outline: none;
      align-items: center;
  
      .cs-chevron {
        transform: rotate(180deg);
        width: 14px;
      }

      .cs-chevron-expanded {
        width: 14px;
      }

      .cs-headerDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .cs-lines {
        width: 30px;
        margin-right: 10px;
      }

      .cs-headerText {
        font-family: "Gotham Rounded Medium";
        position: relative;
        top: 1px;
      }
    }
}

