.infinite-carousel {
  width: 100%;
  background: black;

  .bannerImage {
    margin-bottom: 10px;
    border-radius: 15px;
    width: calc(100% - 20px);
    margin-top: 22px;
    margin-left: 10px;
  }
}
