#Leaderboard {
  padding: 16px 0;
  text-align: left;
  position: relative;

  .You {
    color: #276ef1;
    font-family: 'Gotham Rounded Bold';
  }

  .pageTitle {
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;

    .chevron {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .list,
  .loggedInPlayer {
    display: grid;
    // grid-template-columns: 16px 40px 1fr 56px;
    grid-template-columns: 16px 40px 1fr 104px;
    gap: 16px;
    color: #222222;
    font-size: 14px;
    line-height: 17px;
    padding: 0 16px;

    .red {
      color: red;
    }

    .rank,
    .score {
      display: flex;
      align-items: center;
      font-size: 12px;
      justify-content: flex-end;
    }

    .dp {
      width: 40px;
      height: 40px;
      background: wheat;
      border-radius: 8px;
    }

    .player {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .lastActive {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        line-height: 14px;
        margin-top: 4px;
      }

      .minScore {
        color: #ed4f46;
        font-size: 10px;
        line-height: 14px;
        margin-top: 4px;
      }
    }
  }

  .loggedInPlayer {
    margin-top: 16px;
    background-color: #f4f4ff;
    padding: 16px;
  }

  .minScore {
    margin-top: 8px;
    color: #ed4f46;
    font-family: 'Gotham Rounded Medium';
    font-size: 10px;
    line-height: 16px;

    button {
      background: transparent;
      border: unset;
      font-size: 10px;
      padding: 0;
      color: blue;
      text-decoration: underline;
      outline: none;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
}
