#UpcomingLeaderboards {
  background: white;
  border-radius: 24px 24px 0 0;
  padding: 24px 16px 16px;
  text-align: left;

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .filter {
      color: #616161;
      font-family: 'Gotham Rounded Book';
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px;
    }

    .filterIcon {
      width: 16px;
      margin-right: 8px;
    }
  }

  .pageTitle {
    color: #222222;
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
  }

  .pills {
    display: flex;
    padding: 16px 0;
    overflow: auto;
    align-items: center;

    > div {
      margin-right: 8px;
    }

    .pill {
      border: 0.5px solid #999999;
      border-radius: 100px;
      color: #333333;
      font-family: 'Gotham Rounded Book';
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.12px;
      line-height: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;

      .sort {
        width: 14px;
        margin-right: 6px;
      }

      .cheveron {
        width: 10px;
        margin-left: 6px;
      }
    }
  }

  .loading {
    text-align: center;
    color: teal;
  }

  .label {
    color: #333;
    font-size: 14px;
    letter-spacing: 0.12px;
    line-height: 14px;
    margin-right: 10px;
  }

  .active {
    border: 0.5px solid #276ef1 !important;
    background-color: #ebf2fd !important;
  }
}
