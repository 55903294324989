.Pill {
  padding: 5px 8px;
  border: 1px solid #999999;
  border-radius: 100px;
  color: #333333;
  font-size: 12px;
  letter-spacing: 0.12px;
  line-height: 14px;
  cursor: pointer;
  min-width: fit-content;
}
