.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  width: 100%;
  position: absolute;
  left: 0;

  .sortDiv {
    margin: 0 16px;
  }

  .header {
    opacity: 0.23;
    color: #000000;
    font-family: 'Gotham Rounded Book';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 14px;
    margin-bottom: 6px;
  }

  .pillsDiv {
    display: flex;
    margin-bottom: 8px;

    .pill {
      color: #333333;
      font-family: 'Gotham Rounded Book';
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.12px;
      line-height: 14px;
      border: 0.5px solid #adaaaa;
      border-radius: 100px;
      background-color: white;
      padding: 7px 12px;
      margin-right: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .sort {
      width: 13px;
      margin-left: 6px;
    }
  }

  .hr {
    height: 0.5px;
    background: #979797;
    opacity: 0.43;
  }

  .buttonDiv {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-right: 16px;
    margin-bottom: 20px;
    margin-left: 16px;

    .resultsButton {
      color: #ffffff;
      font-family: 'Gotham Rounded Book';
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.14px;
      line-height: 17px;
      border-radius: 100px;
      background-color: #276ef1;
      border: none;
      outline: none;
      text-decoration: none;
      padding: 10px 16px;
    }

    .clearAllButton {
      color: #999999;
      font-family: 'Gotham Rounded Book';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 14px;
      border: none;
      outline: none;
      text-decoration: none;
      background: none;
    }
  }

  .active {
    border: 0.5px solid #276ef1 !important;
    background-color: #ebf2fd !important;
  }
}
