#HowToWin {
  text-align: left;

  .pageTitle {
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    outline: none;

    .chevron {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .text {
    color: #1f1f1f;
    font-size: 12px;
    line-height: 16px;
  }

  .text2 {
    margin-top: 16px;
    font-size: 12px;
    line-height: 14px;

    span {
      font-family: 'Gotham Rounded Medium';
    }
  }

  .pills {
    display: flex;
    // flex-wrap: wrap;
    padding: 16px 0;
    overflow: auto;

    > div {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  .table {
    width: 100%;
    font-size: 12px;
    background: #f4f4ff;
    padding: 16px 16px 0;
    border-radius: 10px;

    td {
      padding: 8px 0;
      border-top: 1px rgba(60, 60, 67, 0.16) solid;
      color: #666666;
    }

    .right {
      text-align: right;
    }
  }
}
