#rewardDetails {
  background-color: #f4f4ff;
  font-family: 'Gotham Rounded Medium';
  font-size: 12px;
  padding: 16px 16px 8px;
  border-radius: 10px;
  margin-top: 10px;
  text-align: left;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;

  > .title {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 30px 1fr;
    line-height: 17px;

    .arrow {
      font-size: 20px;
      cursor: pointer;
    }

    .header {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  > .subTitle {
    font-size: 12px;
    line-height: 18px;
    padding: 10px 0;
  }

  > .rewardInfoTitle {
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    padding: 10px 0;
  }

  .separator {
    height: 1px;
    background: rgba(60, 60, 67, 0.16);
  }

  .rewards {
    .subTask {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 30px 1fr 30px;

      .progress {
        .circle {
          display: block;
          background: #fff;
          margin: 5px 0 0 0;
          width: 12px;
          height: 12px;
          border-radius: 40px;
          border: 1px solid #666666;
        }

        .vseparator {
          border-left: 1px solid #666666;
          height: 130%;
          margin: 0px 6px;
        }
      }

      .description {
        .title {
          font-weight: bold;
          line-height: 18px;
        }

        .subTitle {
          line-height: 18px;
          padding: 0 5px 0 0;
        }
      }

      .iconInfo {
        img {
          width: 20px;
          height: 20px;
        }

        .iconText {
          font-weight: bold;
        }
      }
    }

    .separator {
      height: 1px;
      background: rgba(60, 60, 67, 0.16);
      margin: 10px 0 10px 30px;
    }
  }
}
