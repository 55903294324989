.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 20%;
    cursor: pointer;
    outline: none;

    .icon {
      width: 30px;
      z-index: 10;
      padding-top: 15px;
    }

    .prize {
      color: #000000;
      font-family: 'Gotham Rounded Book';
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 11px;
      text-align: center;
      z-index: 10;
      margin-top: 8px;
    }

    .prizeAmount {
      color: #ffffff;
      font-family: 'Gotham Rounded Book';
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 12px;
      text-align: center;
      margin-top: 8px;
      z-index: 10;
    }
  }

  .selectedGoal {
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    height: 95px;
    padding: 0 30px;
    position: absolute;
    z-index: 2;
    margin-top: 13px;
    outline: none;
  }

  .hr {
    width: 30%;
    position: absolute;
    height: 2px;
    background: #373636;
  }
}
