.container {
  position: fixed;
  width: 100%;
  top: 10vh;
  left: 0;
  height: 90vh;
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    border-bottom: 0.5px solid rgba(189, 192, 200, 0.6);

    .reset {
      color: #26315f;
      font-family: 'Gotham Rounded Book';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.39px;
      line-height: 19px;
    }

    .apply {
      color: #276ef1;
      font-family: 'Gotham Rounded Book';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.39px;
      line-height: 19px;
    }
  }

  .filterContainer {
    margin: 16px 0;
    overflow: scroll;
    height: 80vh;

    .optionsContainer {
      display: flex;
      flex-direction: column;
      margin: 10px 0;

      .options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 0px;

        .optionContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.5px solid rgba(189, 192, 200, 0.6);
        }

        .option {
          color: #26315f;
          font-family: 'Gotham Rounded Book';
          font-size: 15px;
          font-weight: 300;
          letter-spacing: -0.34px;
          line-height: 17px;
          padding: 12px 16px;
        }

        .icon {
          width: 20px;
          height: 16px;
          margin-right: 16px;
        }
      }
    }

    .header {
      color: #222222;
      font-family: 'Gotham Rounded Book';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0 16px;
    }

    .pillsContainer {
      display: flex;
      flex-direction: column;
      margin: 10px 0;

      .pills {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 16px;
      }

      .pill {
        border: 1px solid #c7cad1;
        border-radius: 18px;
        color: #c7cad1;
        padding: 8px 13px;
        font-family: 'Gotham Rounded Book';
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        margin-right: 16px;
      }

      .filterSelected {
        border: 1px solid #276ef1;
        color: #276ef1;
      }
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      .slider {
        padding: 16px;
        width: calc(100% - 32px);
        margin: 10px 0;
      }

      .headerAndValue {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .iconDiv {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 16px;

    .close {
      width: 16px;
    }
  }
}
