.pWinnersDiv {
    margin: 20px 0 0;
    background: black;

    .mainHeader {
        width: 90%;
        font-family: Gotham Rounded Book;
        display: flex;
        font-size: 14px;
        margin-left: 5%;
        color: white;
        padding-top: 20px;
    }
    
    .mainRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 80px;
        padding: 0 5%;
        border-bottom: 1px solid rgba(204, 204, 204, 0.2);

        .mainImage {
            width: 48px;
            height: 48px;
            margin-bottom: 0;
            border-radius: 10px;
        }

        .mainText {
            font-size: 12px;
            margin: 0 10px;
            color: white;
            text-align: left;
        }

        .pText {
            font-family: Gotham Rounded Medium;
        }

        .pAmount {
            color: #32E0C7;
            font-family: Gotham Rounded Medium;
        }

        .pWinnings {
            font-size: 12px;
            color: #32E0C7;
        }
    }
}