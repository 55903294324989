.LeaderboardCard {
  border-radius: 20px;
  background-color: #edfaf7;
  text-align: left;

  .imgContainer {
    width: 100%;
    background: #f1f1f1;
    border-radius: 20px 20px 0 0;
  }

  .pageTitle {
    display: flex;
    justify-content: space-between;

    .header {
      color: #000000;
      font-family: 'Gotham Rounded Medium';
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .arrow {
      font-size: 19px;
    }
  }

  .details {
    margin-top: 4px;
    color: #666666;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    .lost {
      color: #ed4f46;
    }

    .won {
      color: #32e0c7;
      font-family: 'Gotham Rounded Medium';
    }
  }

  .separator {
    height: 1px;
    margin: 12px 0;
    background: rgba(60, 60, 67, 0.16);
  }

  .moreDetails {
    display: flex;
    justify-content: space-between;

    .lbTypeIcon {
      width: 28px;
      height: 28px;
      background: wheat;
      border-radius: 8px;
      margin-right: 8px;
    }

    .key {
      color: #666666;
      font-size: 12px;
      line-height: 14px;
    }

    .value {
      margin-top: 5px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
