.infinite-carousel {
    width: 100%;
    background: black;
    
    .bannerImage {
        margin-bottom: 10px;
        border-radius: 15px;
        width: 90%;
        margin-top: 22px;
    }
}