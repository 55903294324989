.userWinningInfo {
    border-bottom: 1px solid #282828;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    display: flex;
    color: white;

    .bestPlay {
        font-size: 12px;
        font-family: Gotham Rounded Medium;
        position: relative;
        bottom: 6px;
        
    }

    .firstInfo {
        font-size: 10px;
        color: #CCCCCC;
        position: relative;
        bottom: 5px;
        align-self: flex-start;
    }

    .progressDiv {
        border-radius: 5px;
        overflow: hidden;
        background: black;
    }
}

.playButton {
    box-sizing: border-box;
    border-radius: 21px;
    background-color: white;
    font-family: Gotham Rounded Medium;
    font-size: 14px;
    padding: 12px 38px;
    -webkit-transition: opacity .7s;
    transition: opacity .7s;
    text-align: center;
    width: 90%;
}

.playButtonExtra {
    width: auto;
    margin-right: 30px;
}

.sticky-play-now {
    display: flex;
    transition: opacity 700ms;
}

.sticky-user-data {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #2E2E2E;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    align-self: center;
}

.sticky-normal {
    justify-content: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
    background-color: black;
    padding: 16px 0px;
    align-items: center;
    display: flex;
    z-index: 1000;
    align-self: center;
}
  