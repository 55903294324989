#DatewisePrizeDistribution {
  padding: 0 16px;

  .section {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
  }

  .pageTitle {
    font-family: 'Gotham Rounded Medium', sans-serif;
    font-size: 14px;
    line-height: 17px;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    outline: none;

    .chevron {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .headerText {
    color: #000000;
    font-family: 'Gotham Rounded Book', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    float: left;
  }

  .pillsContainer {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    width: 100%;

    .pill {
      border: 1px solid #c7cad1;
      border-radius: 18px;
      color: #c7cad1;
      padding: 6px 10px;
      font-family: 'Gotham Rounded Medium', sans-serif;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      margin-right: 10px;
      min-width: 44px;
    }

    .filterSelected {
      border: 1px solid #276ef1;
      background-color: #276ef1;
      color: white;
    }
  }

  .headerBold {
    color: #000000;
    font-family: 'Gotham Rounded Medium', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }

  .table {
    width: 100%;
    font-size: 12px;
    background: #f4f4ff;
    padding: 16px 16px 8px;
    border-radius: 10px;
    text-align: left;
    margin-top: 10px;

    td {
      padding: 8px 0;
      border-top: 1px rgba(60, 60, 67, 0.16) solid;
      color: #666666;
      width: 33%;
    }

    .th {
      width: 33%;
    }

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
      color: black;
    }
  }
}
