#PurchaseCard {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: flex-end;

    .bottomCard {
        height: 240px;
        background-color: #FCFCFC;
        width: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 25px 28px;
        padding-bottom: 10px;

        .headerText {
            color: #000000;
            font-family: "Gotham Rounded";
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
        }

        .crossIcon {
            width: 14px;
        }
    }

    .body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 25px;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 14px 0;
            border-bottom: 0.3px solid #CCCCCC;

            .leftText {
                color: #333333;
                font-family: "Gotham Rounded";
                font-size: 15px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
            }

            .rightText {
                color: #333333;
                font-family: "Gotham Rounded";
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 17px;
            }
        }

        .tick {
            width: 64px;
            position: relative;
            bottom: 24px;
        }

        .success {
            color: #333333;
            font-family: "Gotham Rounded";
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
        }

        .lbName {
            color: #666666;
            font-family: "Gotham Rounded Bold";
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
        }
    }

    .payNow {
        background: black;
        color: white;
        font-family: 'Gotham Rounded Medium';
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        padding: 14px 90px 12px;
        border-radius: 100px;
        text-decoration: none;
        position: relative;
        top: 40px;
    }

    .err {
        color: red;
        font-family: "Gotham Rounded";
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 17px;
        align-self: center;
        text-align: center;
        justify-content: center;
        height: 100%;
        top: 60px;
        position: relative;
    }

    .loader {
        align-items: center;
        justify-content: center;
        top: 60px;
        position: relative;
    }
}