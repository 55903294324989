.WeekCalendar {
  color: white;

  .days {
    display: grid;
    grid-template-columns: repeat(7, auto);
    column-gap: 10px;

    .day {
      padding: 12px 8px 6px;
      margin: 0 auto;
      position: relative;
      outline: none;

      .weekDay {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 12px;
      }

      .date {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .selected {
      background: white;
      color: #1f1f1f;
      border-radius: 20px;
    }

    .today {
      position: relative;
      bottom: 0;

      .dot {
        width: 5px;
        height: 5px;
        border-radius: 3px;
        background: yellow;
        margin: 5px auto 0;
      }
    }
  }

  .todayText {
    margin-top: 12px;
    font-size: 12px;
    line-height: 14px;
  }
}
