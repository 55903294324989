.prizeInfo {
  margin: 24px 16px 10px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;

  .trophy {
    width: 20px;
    margin-right: 10px;
  }

  .text {
    text-align: left;
    color: white;
    font-size: 12px;
    line-height: 20px;

    .b {
      font-family: 'Gotham Rounded Medium';
    }
  }
}
