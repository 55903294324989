#DetailsCard {
  text-align: left;
  border-radius: 10px;
  background-color: #f4f4ff;
  margin: 16px;
  padding: 16px;

  .pageTitle {
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    outline: none;

    .chevron {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .cs-content {
    padding: 16px;
    background: #f4f4ff;
    border-radius: 10px;
  }

  .times {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .separator {
    height: 1px;
    background: rgba(60, 60, 67, 0.16);
    margin: 12px 0;
  }

  .b {
    font-family: 'Gotham Rounded Medium', sans-serif;
  }

  .game {
    display: flex;
    align-items: center;

    .logo img {
      width: 46px;
      height: 46px;
      margin-right: 8px;
    }

    .text {
      .name {
        font-family: 'Gotham Rounded Medium', sans-serif;
        font-size: 12px;
        line-height: 14px;
      }

      .other {
        font-family: 'Gotham Rounded Light', sans-serif;
        margin-top: 4px;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .timing,
  .bb,
  .minScore,
  .tds {
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    display: flex;
    align-items: center;

    > div {
      font-size: 12px;
      line-height: 14px;

      > div {
        padding: 2px 0;
      }
    }
  }
}
