.Empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 60px;
    height: 60px;
    background: #f1f1f1;
    border-radius: 8px;
  }

  .message {
    color: #737373;
    // padding: 0 66px;
    margin-top: 12px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
