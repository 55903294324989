.container {
  display: inline-block;
  flex-direction: column;
  background: #282f3c;
  border-radius: 8px;

  .state {
    position: absolute;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.34);
    padding: 2px 8px;
    color: #32e0c7;
    font-family: 'Gotham Rounded Medium', sans-serif;
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
    margin-left: 10px;
  }

  .imageUrl {
    width: 100%;
    border-radius: 10px;
    height: 65%;
  }

  .bottomContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-top: 2px;

    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        color: #ffffff;
        font-family: 'Gotham Rounded Medium', sans-serif;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0;
      }

      .time {
        color: #878787;
        font-family: 'Gotham Rounded Book', sans-serif;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 10px;
        margin: 0;
      }
    }

    .arrow {
      margin-right: 20px;
      height: 20px;
    }
  }
}
