#PlayerSummary {
  text-align: left;
  color: #222222;
  font-size: 14px;
  line-height: 17px;

  .b {
    font-family: 'Gotham Rounded Medium';
  }

  .separator {
    height: 1px;
    background: #f1f1f1;
    padding: 0;
  }

  > div {
    padding: 16px 0;
  }

  .score {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time {
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 14px;
    }
  }

  .more {
    height: 26px;
    width: 117px;
    border: 1px solid #276ef1;
    border-radius: 12px;
    padding: 0;
    display: grid;
    place-items: center;
    color: #276ef1;
    border: 1px #276ef1 solid;
    margin-top: 4px;
    font-size: 12px;
  }
}
