#CurrentRank {
  padding: 16px;
  border-radius: 10px;
  text-align: left;
  font-size: 12px;
  line-height: 14px;

  .titleDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .pageTitle {
      color: #276ef1;
      font-family: 'Gotham Rounded Medium';
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 16px;
    }

    .cardIcon {
      width: 22px;
      position: relative;
      bottom: 8px;
    }
  }

  .currentRank {
    margin-bottom: 24px;
    span {
      font-family: 'Gotham Rounded Medium';
    }
  }

  .currentScore {
    margin-bottom: 8px;
    span {
      font-family: 'Gotham Rounded Medium';
    }
  }

  .progress {
    height: 4px;
    border-radius: 2px;
    margin-bottom: 8px;
  }
}
