#LeaderboardsList {
  background: black;

  .PageTitle {
    text-align: left;
    padding: 16px 16px 0;
    color: #ffffff;
    font-family: 'Gotham Rounded Medium', sans-serif;
    font-size: 16px;
    line-height: 17px;
  }

  .myLeaderboards {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background-color: #276ef1;
    margin: 16px;
    height: 56px;
    align-items: center;
    margin-top: 0px;
    position: relative;
    top: 16px;
    justify-content: space-between;

    .textContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .arrow {
      width: 16px;
    }

    .arrowContainer {
      background-color: rgba(0, 0, 0, 0.15);
      width: 32px;
      height: 32px;
      align-items: center;
      display: flex;
      margin-right: 16px;
      justify-content: center;
      border-radius: 50%;
    }

    .leftImage {
      width: 20px;
      height: 20px;
      margin-left: 16px;
    }

    .text {
      color: #ffffff;
      font-family: 'Gotham Rounded Book', sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 0 0 16px;
    }
  }
}
