@font-face {
  font-family: 'Gotham Rounded Bold';
  src: local('Gotham Rounded Bold'),
    url(./assets/fonts/GothamRounded-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham Rounded Book';
  src: local('Gotham Rounded Book'),
    url(./assets/fonts/GothamRounded-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  src: local('Gotham Rounded Medium'),
    url(./assets/fonts/GothamRounded-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham Rounded Light';
  src: local('Gotham Rounded Light'),
    url(./assets/fonts/GothamRounded-Light.otf) format('opentype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Gotham Rounded Book', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}


.BrainhubCarousel {
  height: 100px !important;
}

.BrainhubCarouselItem {
  margin-bottom: 0px !important;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  height: 100px !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
  background: #728EE3 !important;
  width: 7px !important;
  height: 7px !important;
  
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background: white !important;
  width: 7px !important;
  height: 7px !important;
}


.carousel-dots li.carousel-dots-active button:before {
  color: #728EE3 !important
}

.carousel-dots li button:before {
  color: white !important
}

.carousel-dots {
  margin: 4px;
  position: relative;
  bottom: 12px;
}

.carousel-dots li button:before {
  width: 10px;
  height: 10px;
  font-size: 30px !important
}