.GeneralRules {
  background: black;
  text-align: left;

  .pageTitle {
    color: white;
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 24px;
    padding-left: 20px;
  }

  ol {
    padding-inline-start: 40px;
    margin-bottom: 0;

    li {
      color: #ccc;
      font-size: 12px;
      line-height: 20px;
      padding-bottom: 16px;
    }
  }
}
