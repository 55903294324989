#PrizeDistributionTable {
  padding: 0 16px;

  .pageTitle {
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    outline: none;

    .chevron {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .table {
    width: 100%;
    font-size: 12px;
    background: #f4f4ff;
    padding: 16px 16px 8px;
    border-radius: 10px;
    text-align: left;
    margin-top: 10px;

    td {
      padding: 8px 0;
      border-top: 1px rgba(60, 60, 67, 0.16) solid;
      color: #666666;
      width: 25%;
    }

    .th {
      width: 33%;
    }

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
    }

    .bold {
      font-weight: bold;
    }

    .black {
      color: black;
    }
  }
}
