#LeaderboardDetails {
  .pageTitle {
    background: black;
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Gotham Rounded Medium';
    text-align: left;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-left: 16px;

    img {
      margin-right: 8px;
      margin-left: 12px;
    }
  }

  .header {
    text-align: left;
    background: black;
    padding: 16px 16px 22px;
    border-radius: 0 0 0 24px;

    .contestDescription {
      color: #afabab;
      font-size: 12px;
      line-height: 20px;
    }

    .timeRemaining {
      opacity: 0.6;
      color: #ffffff;
      font-size: 14px;
      line-height: 17px;
      margin-top: 24px;
    }

    .cta {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .time {
        color: #ffffff;
        font-size: 10px;
        letter-spacing: 0.33px;
        line-height: 12px;
        margin-top: 16px;

        span {
          font-size: 24px;
        }
      }

      .play {
        background: white;
        color: black;
        font-family: 'Gotham Rounded Medium';
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        padding: 14px 19px 12px;
        border-radius: 100px;
        text-decoration: none;
        border: none;
      }
    }
  }

  .playBlack {
    height: 42px;
    width: 216px;
    border-radius: 100px;
    background-color: #000000;
    color: #ffffff;
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
    text-decoration: none;
    margin-top: 16px;
    border: none;
  }

  .playWhite {
    height: 42px;
    width: 240px;
    border-radius: 100px;
    background-color: #ffffff;
    font-family: 'Gotham Rounded Medium';
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    border: none;
  }
}

.loading {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}
