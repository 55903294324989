#LeaderboardAndSummary {
  .tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: solid 1px rgba(60,60,67,0.16);
    border-top: solid 1px rgba(60,60,67,0.16);

    > div {
      color: #222222;
      font-size: 14px;
      line-height: 17px;
      padding: 16px 0;
      opacity: 0.3;
      cursor: pointer;
    }

    .selected {
      font-family: 'Gotham Rounded Medium';
      border-bottom: 2px #32e0c7 solid;
      opacity: 1;
      outline: none;
    }
  }
}
