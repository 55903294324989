.infinite-carousel {
    width: 100%;
    background: black;

    .mega-carousel {
        width: 87% !important;
        display: inline-block;
        text-align: left;
        padding: 10px 10px;
        height: 100px;

        .image-text-div {
            display: flex;
            align-items: center;
            color: white;

            .carouselImage {
                margin-bottom: 0;
                border-radius: 50%;
            }

            .carousel-header {
                display: flex;
                flex-direction: column;
                padding: 0px 12px;

                .name {
                    font-size: 12px;
                    font-family: Gotham Rounded Medium;
                    padding-top: 8px;
                    bottom: 6px;
                    position: relative;
                }

                .joinDate {
                    font-size: 10px;
                    font-family: Gotham Rounded;
                    position: relative;
                    bottom: 4px;
                    color: #CCCCCC;
                }
            }
        }

        .description {
            color: white;
            position: relative;
            top: 10px;
        }
    }
}